/* eslint-disable react/prop-types */
import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';

// Import css files for slick slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';
import { graphql, useStaticQuery } from 'gatsby';

const useFixedLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "jpsstore-logo" } }) {
        edges {
          node {
            name
            image: childImageSharp {
              fixed(width: 130) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const logo = data?.allFile?.edges?.[0]?.node || null;
  const logoFixedImage = logo?.image?.fixed || null;

  return logoFixedImage;
};

const SingleBikeSlider = props => {
  const { allImages, hasLogo } = props;

  const settings = {
    customPaging: i => {
      return (
        <a>
          <Img fluid={{ ...allImages[i].image.asset.fluid, aspectRatio: 1.2 }} alt="first slider image" />
        </a>
      );
    },
    dots: true,
    infinite: true,
    fade: false,
    cssEase: 'linear',
    centerMode: false,
    dotsClass: 'slick-dots slick-thumb',
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    speed: 800,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 800,
          pauseOnHover: false,
        },
      },
    ],
  };

  const logoFixedImage = useFixedLogo();

  return (
    <section className="single-bike-slider">
      <Slider {...settings}>
        {allImages.map((slide, index) => {
          return (
            <div className="custom-slide-content">
              {hasLogo && logoFixedImage && index === 0 ? (
                <span className="logo">
                  <Img fixed={logoFixedImage} />
                </span>
              ) : null}
              <Img
                className="slide-bg-image"
                fluid={{ ...slide.image.asset.fluid, aspectRatio: 1.2 }}
                alt="slider images"
              />
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default SingleBikeSlider;
