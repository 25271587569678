import React from 'react';

const googleReviewsLink =
  'https://www.google.com/search?rlz=1C5GCEM_enRS994RS994&sxsrf=ALiCzsYMYHAjMTD2Ei9L4ImUHsJ3yT0AIQ:1652025332427&q=jp+superbikes+google+reviews&spell=1&sa=X&ved=2ahUKEwiOp-X4odD3AhWPtKQKHYkhAGIQBSgAegQIARAx&biw=1728&bih=968&dpr=2#lrd=0x88dca8979581a11f:0x32c151d0ef471c6c,1,,,';

export const BikeReview = ({ link = googleReviewsLink, text }) => {
  return (
    <div className="single-bike-reviews">
      <h5 className="text-black n-t-m n-b-m bold">Customers Reviews</h5>
      <hr className="hr-reviews" />
      <div className="single-quote-review">
        <p style={{ marginBottom: '20px' }}>{text}</p>
        <a className="see-more" href={link}>
          See more reviews
        </a>
      </div>
    </div>
  );
};
