/* eslint-disable react/prop-types */

import React from 'react';
import { Link } from 'gatsby';

import './index.scss';
import { Layout, SEO } from '../components';
import SingleBikeSlider from '../components/Sliders/SingleBikeSlider';
import { BikeReview } from '../components/BikeReview';

const conditionMap = {
  n: 'NEW',
  u: 'USED',
};

const getValue = (value, unit) => {
  if (!value) return '/';

  return `${value} ${unit}`;
};

const SpecsTable = ({ node }) => {
  const title = node.title || ''; // used
  const price = node.price || '';
  const status = node.status || ''; // used
  const color = node.color.name || ''; // used
  const serialNumber = node.serialNumber || ''; // used

  const motor = node.motor || '';
  const breakType = node.breakType || ''; // used
  const weight = node.weight || '';
  const maxRange = node.maxRange || ''; // used
  const maxSpeed = node.maxSpeed || ''; // used
  const wheelSize = node.wheelSize || ''; // used
  const frameSize = node.frameSize || ''; // used
  const suspension = node.suspension || ''; // used
  const numberOfGears = node.numberOfGears || ''; // used
  const frameMaterial = node.frameMaterial || ''; // used

  const specificationMap = [
    {
      first: { label: 'Wheel size', value: getValue(wheelSize, 'in') },
      second: { label: 'Frame size', value: getValue(frameSize, 'in') },
    },
    {
      first: { label: 'Max range', value: getValue(maxRange, 'miles') },
      second: { label: 'Top speed', value: getValue(maxSpeed, 'mph') },
    },
    {
      first: { label: 'Approximate weight', value: getValue(weight, 'kg') },
      second: { label: 'Gears', value: numberOfGears },
    },
    {
      first: { label: 'Motor', value: getValue(motor, 'Watt') },
      second: { label: 'Break type', value: breakType },
    },
    {
      first: { label: 'Frame material', value: frameMaterial },
      second: { label: 'Suspension', value: suspension },
    },
    {
      first: { label: 'Color', value: color },
      second: { label: 'Condition', value: conditionMap[status] },
    },
    {
      first: { label: 'Serial number', value: serialNumber },
      second: { label: 'Price', value: `$${price}` },
    },
  ];

  return (
    <div className="spec-table">
      <div className="table-header">
        <p>
          <strong>Key Features</strong> {title}
        </p>
      </div>

      <div className="table-content">
        <table>
          <tbody>
            {specificationMap.map(({ first, second }) => (
              <tr>
                {first && first.value && (
                  <>
                    <td>{first.label}</td>
                    <td itemProp="name">
                      <strong>{first.value}</strong>
                    </td>
                  </>
                )}

                {second && second.value && (
                  <>
                    <td>{second.label}</td>
                    <td>
                      <strong>{second.value}</strong>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SingleEbike = pageData => {
  const { pageContext: node } = pageData;

  const imagesArray = node.images;
  const allImages = [node.featuredImage, ...imagesArray];

  const { slug } = node;
  const title = node.title || '';
  const description = node.description || '';
  const hasLogo = node.hasLogo || null;

  const metadata = node.metadata || {
    title,
    description,
    image: node.featuredImage.image,
    keywords: `used e-bikes, e-bikes for sale, ${title}`,
  };

  return (
    <Layout>
      <SEO path={`/e-bikes/${slug.current}`} {...metadata} title={title} />

      <section className="single-bike">
        <div className="container">
          <div className="section-header">
            <h1 className="section-title n-b-m n-t-m text-black">{title}</h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">{title}</li>
            </ul>
          </div>
        </div>
        <hr />

        <div className="container">
          <div className="section-main">
            <div className="single-content">
              <div className="single-content-slider">
                <SingleBikeSlider allImages={allImages} hasLogo={hasLogo} />
                <SpecsTable node={node} />
                <div className="bike-description">
                  <h3 className="model text-black n-t-m n-b-m">{title}</h3>
                  <p>{description}</p>
                </div>
              </div>
            </div>
            <BikeReview
              text="I recently purchased a BMW S1000rr from JP Superbikes and it was an exceptional experience. Not only
                  was the bike in better condition than advertised, but John was very professional, knowledgeable and
                  you can tell he is passionate about what he does."
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SingleEbike;
